import { environment } from '@env'
import { Injectable } from '@angular/core'
import { logger } from 'nx/src/utils/logger'
import { HttpClient } from '@angular/common/http'
import { catchError, map, Observable, of } from 'rxjs'
import { VehicleBlockTimeRequest, VehicleModel, VVehicleBlockTimeModel } from '@domain/vehicle'
import { DayPilot } from '@daypilot/daypilot-lite-angular'
import CalendarConfig = DayPilot.CalendarConfig
import CalendarColumnData = DayPilot.CalendarColumnData

@Injectable({
  providedIn: 'root'
})
export class VehicleService {

  constructor(
    private http: HttpClient
  ) { }

  getVehicles(): Observable<VehicleModel[]> {
    const url = `${environment.apiUrl}/vehicles?active=false`
    return this.http.get<VehicleModel[]>(url).pipe(
      map(values => {
        return values
      })
    )
  }

  getActiveVehicles(): Observable<VehicleModel[]> {
    const url = `${environment.apiUrl}/vehicles?active=true`
    return this.http.get<VehicleModel[]>(url).pipe(
      map(values => {
        return values
      })
    )
  }

  getCalResources(): Observable<CalendarColumnData[]> {
    const url = `${environment.apiUrl}/vehicles/calendar-resources`
    return this.http.get<CalendarColumnData[]>(url).pipe(
      map(values => {
        return values
      })
    )
  }

  getVehiclesById(id: number): Observable<VehicleModel[]> {
    const url = `${environment.apiUrl}/vehicles/${id}`
    return this.http.get<VehicleModel[]>(url).pipe(
      map(values => {
        return values
      })
    )
  }

  getVehiclesByUserId(id: number): Observable<VehicleModel> {
    const url = `${environment.apiUrl}/vehicles/users/${id}`
    return this.http.get<VehicleModel[]>(url).pipe(
      map(values => {
        return values[0]
      })
    )
  }

  getBlockTimesByVehicleId(id: number): Observable<VVehicleBlockTimeModel[]> {
    const url = `${environment.apiUrl}/vehicles/${id}/block-times`
    logger.debug('vehicle-service#getBlockTimesByVehicleId: url=', url)
    return this.http.get<VVehicleBlockTimeModel[]>(url).pipe(
      map(values => {
        return values
      })
    )
  }

  addVehicle(vehicle: VehicleModel): Observable<any> {
    const url = `${environment.apiUrl}/vehicles`
    // return this.http.post<number>(url, vehicle).pipe(
    const res = this.http.post(url, vehicle).pipe(
      map(value => {
        return value
      })
      // catchError(error => {
      //   logger.error(`Error: $error`)
      //   return of(null)
      // }),
    )
    logger.debug('vehicle-service#addVehicle: res=', res)
    return res
    /*
     return this.http.post<VehicleModel>(url, vehicle).pipe(
     catchError(this.handleError('addVehicle', vehicle)),
     )
     */
  }

  updateVehicle(vehicle: VehicleModel): Observable<boolean> {
    const url = `${environment.apiUrl}/vehicles`
    // return this.http.put<boolean>(url, vehicle).pipe(
    const res = this.http.put<boolean>(url, vehicle).pipe(
      map(value => {
        return value
      })
    )
    logger.debug('vehicle-service#updateVehicle: res=', res)
    return res
    /*
     return this.http.put<boolean>(url, vehicle).pipe(
     catchError(this.handleError('updateVehicle', vehicle)),
     )
     */
  }

  deleteVehiclesByIds(ids: number[]): Observable<any> {
    const url = `${environment.apiUrl}/vehicles/${ids}`
    logger.debug('vehicle-service#deleteVehiclesByIds: ids=', ids, 'url=', url)
    return this.http.delete(url).pipe(
      catchError(this.handleError('deleteVehicle'))
    )
  }

  addBlockTime(blockTime: VehicleBlockTimeRequest): Observable<any> {
    const url = `${environment.apiUrl}/vehicles/block-times`
    // return this.http.post<number>(url, vehicle).pipe(
    const res = this.http.post(url, blockTime).pipe(
      map(value => {
        return value
      })
    )
    logger.debug('vehicle-service#addBlockTime: res=', res)
    return res
  }

  deleteBlockTimesByIds(ids: number[]): Observable<any> {
    const url = `${environment.apiUrl}/vehicles/block-times/${ids}`
    const res = this.http.delete(url).pipe(
      map(value => {
        return value
      })
    )
    logger.debug('vehicle-service#deleteBlockTimesByIds: res=', res)
    return res
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<boolean> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error) // log to console instead
      // TODO: better job of transforming error for vehicle consumption
      // this.log(`${operation} failed: ${error.message}`)
      // Let the app keep running by returning an empty result.
      return of(false)
    }
  }
}
